import axios from 'axios'
import router from '@admin/router'
import i18n from '../../i18n'

const baseAxios = axios.create({
  baseURL: `${import.meta.env.VITE_API_URL}/api`,
  headers: {
    Accept: 'application/json',
  },
})

/**
 * Refresh the access token
 * @returns {Promise<string>} The new access token
 */
const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem('refresh_token')
  const response = await axios.post(`${import.meta.env.VITE_API_URL}/api/oauth/token`, {
    grant_type: 'refresh_token',
    refresh_token: refreshToken,
    client_id: import.meta.env.VITE_CLIENT_ID,
    client_secret: import.meta.env.VITE_CLIENT_SECRET
  })

  const { access_token, expires_in, refresh_token } = response.data
  localStorage.setItem('access_token', access_token)
  localStorage.setItem('expires_in', expires_in)
  localStorage.setItem('refresh_token', refresh_token)

  return access_token
}

baseAxios.interceptors.request.use(async (config) => {
  const token = localStorage.getItem('access_token')
  const expiresIn = localStorage.getItem('expires_in')
  const expirationTime = new Date(expiresIn).getTime()
  const now = new Date().getTime()

  if (token) {
    if (now >= expirationTime) {
      // Token is expired, try to refresh
      try {
        const newToken = await refreshAccessToken()
        config.headers.Authorization = `Bearer ${newToken}`
      } catch (error) {
        localStorage.removeItem('access_token')
        router.push('/sign_in')
      }
    } else {
      config.headers.Authorization = `Bearer ${token}`
    }
  }

  config.headers['Accept-Language'] = i18n.global.locale.value

  return config
})

baseAxios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true

      try {
        const newToken = await refreshAccessToken()
        originalRequest.headers.Authorization = `Bearer ${newToken}`
        return baseAxios(originalRequest)
      } catch (refreshError) {
        localStorage.removeItem('access_token')
        router.push('/sign_in')
      }
    }

    return Promise.reject(error)
  }
)

export default baseAxios
