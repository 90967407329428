<template>
  <nav class="menu">
    <router-link
      class="menu-section-link body bold"
      :to="{ name: 'SubsidiesList' }"
    >
      <div class="link-icon"><SubsidySchemesIcon /></div>

      <span>{{ $t('aside.subsidies.title') }}</span>
      <span></span>
    </router-link>
    <router-link
      class="menu-section-link body bold"
      :to="{ name: 'ApplicationsList' }"
    >
      <div class="link-icon">
        <ApplicationIcon />
      </div>

      <span>{{ $t('application.my_applications') }}</span>
      <span></span>
    </router-link>
    <router-link class="menu-link" :to="{ name: 'ActiveApplications' }">
      <span
        >{{ $t('application.active') }}
        <template
          v-if="
            countersData &&
            (countersData.active_application_count ||
              countersData.active_application_count == 0)
          "
          >{{ '(' + countersData.active_application_count + ')' }}</template
        ></span
      >
    </router-link>
    <router-link class="menu-link" :to="{ name: 'HistoryApplications' }">
      <span
        >{{ $t('application.history') }}
        <template
          v-if="
            countersData &&
            (countersData.history_application_count ||
              countersData.history_application_count == 0)
          "
          >{{ '(' + countersData.history_application_count + ')' }}</template
        ></span
      >
    </router-link>
    <router-link class="menu-link" :to="{ name: 'DraftApplications' }">
      <span
        >{{ $t('application.draft') }}
        <template
          v-if="
            countersData &&
            (countersData.draft_application_count ||
              countersData.draft_application_count == 0)
          "
          >{{ '(' + countersData.draft_application_count + ')' }}</template
        ></span
      >
    </router-link>

    <router-link
      class="menu-section-link body bold"
      :to="{ name: 'AppealsList' }"
    >
      <div class="link-icon">
        <AppealsIcon />
      </div>

      <span>{{ $t('application.my_appeals') }}</span>
      <span></span>
    </router-link>
    <router-link class="menu-link" :to="{ name: 'OpenAppeals' }">
      <span
        >{{ $t('application.open') }}
        <template
          v-if="
            countersData &&
            (countersData.open_appeal_count ||
              countersData.open_appeal_count == 0)
          "
          >{{ '(' + countersData.open_appeal_count + ')' }}</template
        ></span
      >
    </router-link>
    <router-link class="menu-link" :to="{ name: 'ClosedAppeals' }">
      <span
        >{{ $t('application.closed') }}
        <template
          v-if="
            countersData &&
            (countersData.closed_appeal_count ||
              countersData.closed_appeal_count == 0)
          "
          >{{ '(' + countersData.closed_appeal_count + ')' }}</template
        ></span
      >
    </router-link>

    <!-- PaymentClaims -->
    <router-link
      class="menu-section-link body bold"
      :to="{
        name: 'ListOfPaymentClaims',
      }"
    >
      <div class="link-icon">
        <PaymentClaimIcon />
      </div>

      <span>{{ $t('paymentClaim.my_payment_claims') }}</span>
      <span></span>
    </router-link>

    <router-link
      class="menu-link no-active"
      :to="{
        name: 'ListOfPaymentClaims',
        query: {
          status_in: ['draft'],
        },
      }"
    >
      <span>{{ $t('aside.payment_claims.draft') }}</span>
    </router-link>
    <router-link
      class="menu-link no-active"
      :to="{
        name: 'ListOfPaymentClaims',
        query: {
          status_in: [
            'new_payment_claim',
            'assessment',
            'chief_evaluator_assessment',
            're_evaluation',
          ],
        },
      }"
    >
      <span>{{ $t('aside.payment_claims.in_progress') }}</span>
    </router-link>
    <router-link
      class="menu-link no-active"
      :to="{
        name: 'ListOfPaymentClaims',
        query: {
          status_in: [
            'approved',
            'rejected',
            'court_approved',
            'court_rejected',
          ],
        },
      }"
    >
      <span>{{ $t('aside.payment_claims.final_decision') }}</span>
    </router-link>
    <router-link
      class="menu-link no-active"
      :to="{
        name: 'ListOfPaymentClaims',
        query: {
          status_in: ['sent_for_payment', 'bank_payment_rejected'],
        },
      }"
    >
      <span>{{ $t('aside.payment_claims.sent_for_payment') }}</span>
    </router-link>
    <router-link
      class="menu-link no-active"
      :to="{
        name: 'ListOfPaymentClaims',
        query: {
          status_in: ['withdrawn', 'paid', 'closed'],
        },
      }"
    >
      <span>{{ $t('aside.payment_claims.closed') }}</span>
    </router-link>

    <!-- ContactSupport -->
    <router-link
      class="menu-section-link body bold"
      :to="{ name: 'ContactSupport' }"
    >
      <div class="link-icon">
        <ContactSupportIcon />
      </div>

      <span>{{ $t('auth.support.title') }}</span>
      <span></span>
    </router-link>
  </nav>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import baseAxios from '@user/axios'

import SubsidySchemesIcon from '@user/components/icons/SubsidySchemesIcon.vue'
import AppealsIcon from '@user/components/icons/AppealsIcon.vue'
import ApplicationIcon from '@user/components/icons/ApplicationIcon.vue'
import ContactSupportIcon from '@user/components/icons/ContactSupportIcon.vue'
import PaymentClaimIcon from '@user/components/icons/PaymentClaimIcon.vue'

const countersData = ref(null)
const isLoading = ref(false)

onMounted(async () => {
  try {
    await getCountersData()
  } catch (err) {
    console.log(err)
  }
})
/**
 * Fetches application data from the store asynchronously.
 * @async
 */
const getCountersData = async () => {
  try {
    isLoading.value = true
    const { data } = await baseAxios.get(`stats/sidebar_stats`)
    countersData.value = data
  } catch (error) {
    console.log(error)
  } finally {
    isLoading.value = false
  }
}
</script>

<style lang="scss" scoped>
.menu {
  &-section-link {
    display: flex;
    align-items: center;

    width: 100%;
    padding: 10px;

    font-size: 16px;
    color: var(--app-color-black);
    text-decoration: none;

    border-radius: 4px;
    box-shadow: none;

    .link-icon {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 24px;
      height: 24px;
      margin-right: 10px;

      transition: all 0.2s;
    }

    &.active {
      color: var(--app-color-primary);
      background-color: var(--app-color-blue-100);
    }

    &:hover,
    &:active,
    &:focus {
      color: var(--app-color-primary);
    }

    & + .menu-link {
      margin-top: 4px;
    }
  }

  &-link {
    display: flex;
    align-items: center;

    margin-left: 44px;

    font-size: 16px;
    line-height: 19px;
    color: var(--app-color-black);
    text-decoration: none;

    background: none;
    box-shadow: none;

    &.no-active {
      font-weight: 400;
      color: var(--app-color-black);
    }

    &.router-link-exact-active:not(.no-active) {
      font-weight: 500;
      color: var(--app-color-primary);
    }

    &:hover,
    &:active,
    &:focus {
      color: var(--app-color-primary);
    }

    & + .menu-link {
      margin-top: 16px;
    }
  }
}

.active {
  font-weight: 500;
  color: var(--app-color-primary);
}
</style>
