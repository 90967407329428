import axios from 'axios'
import router from '@user/router'
import i18n from '../../i18n'

const baseAxios = axios.create({
  baseURL: `${import.meta.env.VITE_API_URL}/api`,
  headers: {
    Accept: 'application/json',
  },
})

/**
 * Refresh the access token
 * @returns {Promise<string>} The new access token
 */
const refreshAccessToken = async () => {
  try {
    const refreshToken = localStorage.getItem('external_refresh_token')
    if (!refreshToken) {
      throw new Error('No refresh token available')
    }

    const response = await axios.post(`${import.meta.env.VITE_API_URL}/api/oauth/token`, {
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
      client_id: import.meta.env.VITE_EXT_CLIENT_ID,
      client_secret: import.meta.env.VITE_EXT_CLIENT_SECRET
    })

    const { access_token, expires_in, refresh_token } = response.data
    const expirationTime = Date.now() + expires_in * 1000

    localStorage.setItem('external_access_token', access_token)
    localStorage.setItem('external_expires_at', expirationTime.toString())
    localStorage.setItem('external_refresh_token', refresh_token)

    return access_token
  } catch (error) {
    localStorage.removeItem('external_access_token')
    localStorage.removeItem('external_expires_at')
    localStorage.removeItem('external_refresh_token')
    throw error
  }
}

baseAxios.interceptors.request.use(async (config) => {
  const token = localStorage.getItem('external_access_token')
  const expiresAt = localStorage.getItem('external_expires_at')
  const now = Date.now()

  const REFRESH_THRESHOLD = 30 * 1000 // 30 seconds
  if (token) {
    if (now >= (parseInt(expiresAt) - REFRESH_THRESHOLD)) {
      // Token is expired or about to expire, try to refresh
      try {
        const newToken = await refreshAccessToken()
        config.headers.Authorization = `Bearer ${newToken}`
      } catch (error) {
        // Очищаємо токени при помилці
        localStorage.removeItem('external_access_token')
        localStorage.removeItem('external_expires_at')
        localStorage.removeItem('external_refresh_token')

        setTimeout(() => {
          if (
            router.currentRoute.value.path !== '/authorize' &&
            router.currentRoute.value.meta.auth
          ) {
            router.push('/sign_in')
          }
        }, 100)
      }
    } else {
      config.headers.Authorization = `Bearer ${token}`
    }
  }

  config.headers['Accept-Language'] = i18n.global.locale.value

  return config
})

baseAxios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      setTimeout(() => {
        if (
          router.currentRoute.value.path !== '/authorize' &&
          router.currentRoute.value.meta.auth
        ) {
          router.push('/sign_in')
        }
      }, 100)
    }

    return Promise.reject(error)
  }
)

export default baseAxios
