import { defineStore, acceptHMRUpdate } from 'pinia'
import baseAxios from '@user/axios'

export const useUserStore = defineStore({
  id: 'user',
  state: () => ({
    user: null,
    isInitialized: false,
    applications: [],
  }),

  actions: {
    /**
     * Fetches the current user's data from the server.
     * @returns {Promise<void>}
     */
    async fetchUser() {
      try {
        const { data } = await baseAxios.get('/employers/me')
        this.user = data
      } catch (error) {
        console.error('Failed to fetch user data', error)
      } finally {
        this.isInitialized = true
      }
    },

    /**
     * Sets the new user data in the store.
     * @param {object} newUserData - The new user data to set.
     */
    setUser(newUserData) {
      this.user = newUserData
    },

    /**
     * Signs in a user with the given payload.
     * @param {string} code - The sign-in code.
     * @returns {Promise<object>} - The signed-in user's data.
     */
    async signIn(code) {
      const { data } = await baseAxios.post(`/cy_logins/authorize?code=${code}`, {
          client_id: import.meta.env.VITE_EXT_CLIENT_ID,
          client_secret: import.meta.env.VITE_EXT_CLIENT_SECRET,
      })
      this.user = data
      localStorage.setItem('external_access_token', data.token)
      localStorage.setItem('external_refresh_token', data.refresh_token)
      const expirationTime = Date.now() + data.expires_in * 1000
      localStorage.setItem('external_expires_at', expirationTime)
      return data
    },

    /**
     * Logs out the current user.
     * @returns {Promise<object>} - The response data.
     */
    async logOut() {
      const { data } = await baseAxios.delete('/cy_logins/logout', {
        params: {
          client_id: import.meta.env.VITE_EXT_CLIENT_ID,
          client_secret: import.meta.env.VITE_EXT_CLIENT_SECRET,
        },
      })
      this.user = null
      localStorage.removeItem('external_access_token')
      localStorage.removeItem('external_refresh_token')
      localStorage.removeItem('external_expires_at')
      return data
    },
    /**
     * Fetches the user's applications.
     *
     * This function sends a PATCH request to the '/users' endpoint with the provided payload.
     * It then returns the returned data.
     * @async
     * @param {object} payload - The fetch applications data.
     * @returns {object} The fetched applications data.
     */
    async getApplications(payload) {
      const { data } = await baseAxios.get('/applications', { params: payload })
      this.applications = data.items
      return data
    },

    /**
     * Fetches the user's applications.
     *
     * This function sends a PATCH request to the '/users' endpoint with the provided payload.
     * It then returns the returned data.
     * @async
     * @param {object} payload - The fetch applications data.
     * @returns {object} The fetched applications data.
     */
    async getAppeals(payload) {
      const { data } = await baseAxios.get('/appeals', { params: payload })
      return data
    },

    /**
     * Withdraws a specific application.
     *
     * This function sends a PATCH request to the '/applications/{id}/withdraw' endpoint with the provided id.
     * It then returns the response data.
     * @async
     * @param {string} id - The id of the application to withdraw.
     * @returns {object} The response data.
     */
    async withdrawApplication(id) {
      return await baseAxios.patch(`applications/${id}/withdraw`)
    },

    /**
     * Fetches a specific application's data.
     *
     * This function sends a GET request to the '/applications/{id}' endpoint with the provided id.
     * It then returns the returned data.
     * @async
     * @param {string} id - The id of the application to fetch.
     * @returns {object} The fetched application data.
     */
    async getApplication(id) {
      const { data } = await baseAxios.get(`/applications/${id}`)
      return data
    },

    /**
     * Fetches a specific application's data.
     *
     * This function sends a GET request to the '/applications/{id}' endpoint with the provided id.
     * It then returns the returned data.
     * @async
     * @param {string} id - The id of the application to fetch.
     * @returns {object} The fetched application data.
     */
    async getAppeal(id) {
      const { data } = await baseAxios.get(`/appeals/${id}`)
      return data
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
}
